/*
 * @Descripttion:
 * @Author: sunchen
 * @Date: 2023-08-01 15:47:31
 * @LastEditors: sunchen
 * @LastEditTime: 2023-08-01 15:57:43
 */
import CryptoJS from 'crypto-js'

//解密方法
//十六位十六进制数作为秘钥
const aeskey = CryptoJS.enc.Utf8.parse('hzjt-suanfangbao');
//十六位十六进制数作为秘钥偏移量
const aesiv = CryptoJS.enc.Utf8.parse('abcdefghijklmnop');

// 加密
function encrypt(data) {
  const srcs = CryptoJS.enc.Utf8.parse(data);
  const encrypted = CryptoJS.AES.encrypt(srcs, aeskey, {
    iv: aesiv,
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  //返回base64加密结果
  return encrypted.toString();
}

//解密
function decrypt(data) {
  // data是base64编码数据
  const decrypt = CryptoJS.AES.decrypt(data, aeskey, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  const decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
  return decryptedStr.toString();
}

export { encrypt, decrypt };
